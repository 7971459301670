import React from "react"

import { Page } from "../components/layout"
import SEO from "../components/seo"
import AboutPageContent from "../components/about"
import { graphql } from "gatsby"

const IndexPage = ({data}) => (
  <Page>
    <SEO title="Pro and Co • Architecture • Urbanisme • Alexia & Michel Protsenko" />
    <AboutPageContent metadata={data.meta.siteMetadata}/>
  </Page>
)

export default IndexPage


export const query = graphql`
query indexMeta {
  meta : site {
    siteMetadata {
      siteUrl
      title
      description
      instagram
    }
  }
}
`