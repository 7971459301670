import React from "react"
import Helmet from "react-helmet"

const AboutPageContent = ({metadata}) => (
  <div className="page page__home">
    <div className="page__header">
      <h1 className={"h__title"}>Pro and&nbsp;co</h1>
      <h2 className={"h__title h__title--secondary"}>À&nbsp;propos</h2>
    </div>
    <div className="page__home__main">
      <Helmet>
        <link rel="canonical" href={`${metadata.siteUrl}`} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": metadata.title,
            "alternateName": metadata.description,
            "url": metadata.siteUrl,
            "logo": `${metadata.siteUrl}/images/intro-logo-pro-and-co.png`,
            "sameAs": metadata.instagram
          })}
        </script>
      </Helmet>
      <div className="page__home__intro">
        <p>
          Alexia et Michel Protsenko fondent ensemble
          Pro&nbsp;and&nbsp;co, une entreprise familiale, à taille humaine, née
          d’une passion partagée pour l’architecture et l’urbanisme.
          Les frontières entre résider et travailler ou
          se divertir, entre sphères privées et publiques, entre ville et campagne, se recomposent.
          Au&nbsp;croisement
          de la théorie et de la pratique, ils décident d’agir avec ambition pour inventer de nouvelles procédures
          architecturales et urbaines. C’est&nbsp;de&nbsp;manière complémentaire qu’ils s’appuient sur un réseau de
          spécialistes externes polyvalents&#x202F;: ingénieurs, économistes, paysagistes, environnementalistes ainsi que
          des artisans fidèles et compétents.
          <br/>Pro&nbsp;and&nbsp;co cultive cette polyvalence des interventions aussi bien
          pour des clients publics que privés.
          Ses interventions ont lieu sur l’ensemble du territoire national et
          développe sa collaboration dans les DOM-TOM ou au niveau international. Son engagement se tourne vers
          une organisation de proximité pour une plus grande efficacité, disponibilité, capacité d’écoute et une
          valorisation des entreprises locales.
        </p>
      </div>

      <div className="page__home__contact">
        <div>
          <h3>Adresse siège</h3>
          237, chemin du Coteau
          <br/>01600 Reyrieux
          <br/>Auvergne-Rhône-Alpes
        </div>
        <div>
          <h3>Instagram</h3>
          <a href="https://www.instagram.com/proandco/">@proandco</a>
        </div>
      </div>

      <div className="page__home__persons">

        <div className="page__home__person">
          <div className="page__home__person__block">
            <h3>Michel Protsenko</h3>
            <p>
              Avec plus de 35 ans d’activité professionnelle,
              Michel Protsenko apporte son expérience d’architecte
              DPLG, urbaniste DIFU et program&shy;miste. Il a été responsable de l’activité d’urbanisme au BETURE
              du groupe Caisse&nbsp;des&nbsp;Dépôts et&nbsp;Consigna&shy;tions puis chez Nexity. Sa démarche se fonde sur sa maturité
              profession&shy;nelle et de sa constante recherche d’idées innovantes. Elle vise l’optimisation du projet
              en terme de qualité, de coûts et de délais ainsi que la satisfaction des clients et des habitants.
              Dévoué à cette profession, il s’est mobilisé pour sa défense en tant qu’administrateur au syndicat
              de la programmation architecturale et d’urbanisme (SYPAA) et administrateur à la fédération des
              métiers de la prestation intellectuelle du conseil, de l’ingénierie et du numérique (CINOV
              Rhône-Alpes). </p>
          </div>
          <div className="page__home__person__block">

            <h4>Études d’Urbanisme</h4>
            <ul>
              <li>PLU, SCOT</li>
              <li>Projet Urbain, éco-quartier</li>
              <li>Renouvellement urbain</li>
              <li>Zone d’activités</li>
              <li>Centre-ville</li>
            </ul>

            <h4>Études préalables</h4>
            <ul>
              <li>Étude d’opportunité</li>
              <li>Réflexion stratégique</li>
              <li>Définition de projet</li>
              <li>Faisabilité et montage de projet</li>
              <li>Assistance à maîtrise d’ouvrage</li>
            </ul>

            <h4>
              Études de programmation </h4>
            <ul>
              <li>Définition des besoins</li>
              <li>Programme général et technique</li>
              <li>Management des études amont</li>
              <li>Estimation des coûts et délais</li>
              <li>Aide au choix de la maîtrise d’œuvre</li>
            </ul>

            <h4>
              Études opérationnelles </h4>

            <ul>
              <li>Diagnostics, scénarios et plan guide</li>
              <li>Concertation et animation</li>
              <li>Coordination des experts</li>
              <li>Obtention des autorisations administratives</li>
              <li>Suivi du projet en phase conception</li>
            </ul>
          </div>
        </div>
        {/* end of Michel */}

        <div className="page__home__person">
          <div className="page__home__person__block">
            <h3>Alexia Protsenko</h3>
            <p>
              Architecte d’intérieur, elle apporte sa vitalité créative et son sens du détail. Issue d’une culture
              cosmopolite et ayant grandit dans l’amour de l’art et de l’architecture près de son père, elle
              s’oriente très jeune vers cette profession.
              Grâce à ses collabo&shy;rations avec des confrères de
              confiance, elle travaille autour de projets résidentiels et commerciaux et décide de prendre son
              envol pour gérer son entreprise. Dynamique et à&nbsp;l’écoute de ses clients, elle crée et trouve des
              solutions adaptées à&nbsp;chaque projet, de la conception jusqu’au suivi des travaux. Elle accompagne
              aujourd’hui les professionnels et les particuliers dans tous leurs projets (aména&shy;gement intérieur,
              rénovation, restructuration de bureaux, construction ou extension bois…) et partage sa passion en
              enseignant le design d’espace à l’école Bellecour de Lyon.

            </p>
          </div>
          <div className="page__home__person__block">

            <h4>Conception</h4>
            <ul>
              <li>Visite sur site, conseils, analyse</li>
              <li>Relevé état des lieux</li>
              <li>Étude de faisabilité</li>
              <li>Esquisse, Avant Projet Sommaire</li>
              <li>Avant Projet Définitif</li>
              <li>Plans d’exécutions</li>
              <li>Mobilier sur mesure</li>
              <li>Imagerie 3D</li>
              <li>Préconisations des matériaux <br/>et du mobilier de commerce</li>
              <li>Planche des tendances</li>
            </ul>

            <h4>Préparation du projet</h4>
            <ul>
              <li>Dossiers administratifs <br/>(déclaration préalable, permis de construire…)</li>
              <li>Descriptifs des travaux</li>
              <li>Consultation des entreprises</li>
              <li>Analyse des offres</li>
            </ul>

            <h4>Suivi de chantier</h4>
            <ul>
              <li>Planification des travaux</li>
              <li>Ordonnancement, pilotage et&nbsp;coordination des travaux (maîtrise&nbsp;d’œuvre)</li>
              <li>Réception des travaux</li>
            </ul>

          </div>
        </div>
        {/* end of Alexia */}

      </div>
    </div>

  </div>
)

export default AboutPageContent